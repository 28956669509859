import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import sliderImage1 from '../images/slider1/barber.png';
import sliderImage2 from '../images/slider1/uczelnia2.png';
import sliderImage3 from '../images/slider1/muzeum.png';
import sliderImage4 from '../images/slider1/pizzeria.png';
import sliderImage5 from '../images/slider1/pokoj.png';
import sliderImage6 from '../images/slider1/salon_kosmetyczny.png';
import sliderImage7 from '../images/slider1/muzeum2.png';
import sliderImage8 from '../images/slider1/szkoła.png';
import sliderImage9 from '../images/slider1/uczelnia.png';
import sliderImage10 from '../images/slider1/inspiracja.png';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  gap: 20px;
  padding-bottom: 3rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const SliderWrapper = styled.div`
  width: 50%;
  height: 60vh; /* Wysokość slidera */
  position: relative;
  overflow: hidden;
  border-radius: 8px;

  @media (max-width: 768px) {
    width: 100%;
    height: 50vh;
  }
`;

const Slide = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${({ bgImage }) => `url(${bgImage})`};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: transform 1s ease-in-out;
`;

const DotContainer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
`;

const Dot = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({ active }) => (active ? 'white' : 'gray')};
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
`;

const TextSection = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 20px;
  }
`;

const Heading = styled.h2`
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #333;
`;

const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
  z-index: 2;
  
`;

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const images = [
    sliderImage1,
    sliderImage2,
    sliderImage3,
    sliderImage4,
    sliderImage5,
    sliderImage6,
    sliderImage7,
    sliderImage8,
    sliderImage9,
    sliderImage10,
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    }, 3000); // Zmiana co 3 sekundy

    return () => clearInterval(intervalId); // Oczyść interval po odmontowaniu komponentu
  }, []);

  return (
    <Container>
      <SliderWrapper>
        <Slide bgImage={images[currentSlide]} />
        <DotContainer>
          {images.map((_, index) => (
            <Dot
              key={index}
              active={index === currentSlide}
              onClick={() => setCurrentSlide(index)}
            />
          ))}
        </DotContainer>
      </SliderWrapper>
      <TextSection>
        <Heading>Druk Ścienny - Nieograniczone Możliwości</Heading>
        <Paragraph>
          Druk ścienny to doskonałe rozwiązanie do personalizacji przestrzeni.
          Możesz go używać w biurach, salonach, restauracjach czy szkołach,
          aby dodać unikalny charakter do każdego pomieszczenia. Nasza technologia
          umożliwia drukowanie na różnych powierzchniach, zapewniając trwałość i
          estetykę. Ożyw swoje wnętrza dzięki naszym innowacyjnym projektom!
        </Paragraph>
      </TextSection>
    </Container>
  );
};

export default Slider;
